// Styles
// CoreUI Icons Set

@import "~@coreui/icons/css/all.css";
// Import Flag Icons Set
@import "./scss/style.scss";
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logo-loader {
  width: 300px;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.icon {
  display: inline-block;
  color: inherit;
  text-align: center;
  fill: currentColor;
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

body {
  background-color: #f9fafb !important;
}

.perfilName {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  display: flex;
  border-radius: 30px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(145, 158, 171, 0.12);
}

.css-1kgdhtw-MuiAvatar-root {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Public Sans, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.css-d0uhtl {
  margin-left: 16px;
}

.css-ixccc3-MuiTypography-root {
  margin: 0;
  font-weight: 600;
  line-height: 1.5714285714285714;
  font-size: 0.875rem;
  font-family: Public Sans, sans-serif;
}

.css-1pqm26d-MuiAvatar-img {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}
.css-ixccc3-MuiTypography-root {
  margin: 0;
  font-weight: 600;
  line-height: 1.5714285714285714;
  font-size: 0.875rem;
  font-family: Public Sans, sans-serif;
}
.css-1aaagy3-MuiTypography-root {
  margin: 0;
  line-height: 1.5714285714285714;
  font-size: 0.875rem;
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  color: #637381;
}
.activeNavBar {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-width: 0;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 44px;
  border-radius: 6px;
  font-family: Public Sans, sans-serif;
  font-weight: 600;
  color: #1877f2;
  text-transform: capitalize;
  background-color: rgba(120, 122, 126, 0.08);
}

.reuniones-dash {
  display: flex;
  flex-direction: row;
  justify-content: first baseline;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 10px;
  background-color: #fff;
  
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.08),
    0 12px 24px -4px rgba(145, 158, 171, 0.08);
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 16px;
  margin-bottom: 10px;
  /* border-left: 3px solid #1877F2;
    border-right: 3px solid #1877F2; */
}

.reuniones-dash2 {
  display: flex;
  flex-direction: row;
  justify-content: first baseline;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 10px;
  background-color: #fff;
  
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.08),
    0 12px 24px -4px rgba(145, 158, 171, 0.08);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 16px;
  margin-bottom: 10px;
  /* border-left: 3px solid #1877F2;
    border-right: 3px solid #1877F2; */
}

.cantidad-reuniones-dash {
  font-size: 2rem;
  font-weight: bold;
  font-family: Public Sans, sans-serif;
  margin-bottom: 0px !important;
}

.contenedor_cantidad {
  flex: 1;
  display: flex;
  gap: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contenedor_cantidad > h6 {
  font-weight: bold;
  line-height: 1.5714285714285714;
  font-size: 0.875rem;
  font-family: Public Sans, sans-serif;
  color: #000;
}

.css-ak1sp9-MuiButtonBase-root-MuiListItemButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.18) !important;
}
.btn-primary {
  color: #fff;
  background-color: rgb(15, 44, 66) !important;
  border-color: midnightblue;
}

.overflow-auto {
}